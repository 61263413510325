import Layout from '@/components/Layout';
import { getSession } from 'next-auth/react';

export default function custom404() {
    return (
        <>
            <div className="terms">
                <div className="inner">
                    <h2>Lo sentimos </h2>
                    <p>
                        Pagina no encontrada
                    </p>
                </div>
            </div>
        </>
    );
}

custom404.getLayout = function getLayout(page) {
    return (
        <Layout
            title="No encontrada| Wooloo"
            className="change-dni form-pages"
        >
            {page}
        </Layout>
    );
};

